import React from "react";
import { i18n } from "../../translate/i18n";
import Button from "./../../components/general/Button";

import "../../assets/styles/components/treinamentos/treinamentos-card.scss";

const TreinamentosCard = (props) => {
  return (
    <div id="treinamentos-card">
      <div className="box bg-color">
        <h1
          className="has-text-centered title"
          style={{ color: props.colorTitle }}
        >
          {props.title}
        </h1>
        <br />
        <div className="columns is-centered is-vcentered">
          <div className="column is-3 image-align">
            <img src={props.image} alt="" />
          </div>
          <div className="column is-6 description-align">
            <p className="description">{props.description}</p>
          </div>
        </div>
        <br />
        <div className="button-align">
          <Button link={props.link}>
            {i18n.t("treinamentos.aplicativo.saibaMais")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TreinamentosCard;

import React, { Component } from "react";
import { i18n } from "../translate/i18n";
import "../assets/styles/pages/desenvolvimento.scss";
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";
import Clube from "../components/Servicos/desenvolvimento/ClubeVantagens";
import CAS from "../components/Servicos/desenvolvimento/CAS";
import Aminthas from "../components/Servicos/desenvolvimento/Aminthas";
import Pajucara from "../components/Servicos/desenvolvimento/Pajucara";
import PioNet from "../components/Servicos/desenvolvimento/PioNet";
import GetinfoTreinamentos from "../components/Servicos/desenvolvimento/GetinfoTreinamentos";

class Desenvolvimento extends Component {
  render() {
    return (
      <div id="desenvolvimento">
        <Servico
          icon="devices_other"
          title={i18n.t("softwareDeveloper.title")}
          descricao={i18n.t("softwareDeveloper.descricao")}
        >
          <Clube />
          <CAS />
          <Aminthas />
          <Pajucara />
          <PioNet />
          <GetinfoTreinamentos />
        </Servico>
        <hr className="margin-hr" />
        <FaleConosco textButton={i18n.t("softwareDeveloper.textButton")} />
      </div>
    );
  }
}

export default Desenvolvimento;

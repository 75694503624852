import React, { Component } from "react";
import { Button } from "bloomer";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import {
  AlertIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  CubeSendIcon,
} from "mdi-react";
import MaskedInput from "react-maskedinput";
import CpfCheck from "cpf-check";
import "../assets/styles/views/inscricao.scss";
import Recaptcha from "react-recaptcha";

class Inscricao extends Component {
  constructor(props) {
    super(props);

    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      course: "",
      payment: "",
      name: "",
      email: "",
      rg: "",
      cpf: "",
      date: "",
      phone: "",
      mother: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      answer: "",
      isValidRecaptcha: false,
      modalIsOpen: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  handleDimissModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ course: "" });
    this.setState({ payment: "" });
    this.setState({ name: "" });
    this.setState({ email: "" });
    this.setState({ rg: "" });
    this.setState({ cpf: "" });
    this.setState({ date: "" });
    this.setState({ phone: "" });
    this.setState({ mother: "" });
    this.setState({ cep: "" });
    this.setState({ address: "" });
    this.setState({ number: "" });
    this.setState({ complement: "" });
    this.setState({ neighborhood: "" });
    this.setState({ city: "" });
    this.setState({ state: "" });
    this.setState({ answer: "" });
  }

  onLoadCallback() {
    // console.log('Recaptcha carregado!');
  }

  verifyCallback() {
    this.setState({ isValidRecaptcha: true });
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onChangeCEP = (e) => {
    this.handleFormChange(e);

    const cepInput = e.target.value;
    // eslint-disable-next-line
    var cep = cepInput.replace(/([_-])/g, "");

    if (cep.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          this.setState({ address: response.data.logradouro });
          this.setState({ neighborhood: response.data.bairro });
          this.setState({ city: response.data.localidade });
          this.setState({ state: response.data.uf });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ address: "" });
      this.setState({ neighborhood: "" });
      this.setState({ city: "" });
      this.setState({ state: "" });
    }
  };

  isValidCPF = (cpfInput) => {
    var cpf = cpfInput.replace(/([._-])/g, "");

    if (CpfCheck(cpf).valid) {
      return true;
    }
    return false;
  };

  isValidDateOfBirth = (dateInput) => {
    var date = dateInput.replace(/([_/])/g, "");
    if (date.length === 8) {
      return true;
    }
    return false;
  };

  isValidPhone = (phoneInput) => {
    var phone = phoneInput.replace(/([ ()_-])/g, "");
    if (phone.length === 11) {
      return true;
    }
    return false;
  };

  isValidCep = (cepInput) => {
    var cep = cepInput.replace(/([_-])/g, "");
    if (cep.length === 8) {
      return true;
    }
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ answer: "" });

    if (!this.state.isValidRecaptcha) {
      this.setState({
        answer: [<CloseCircleIcon key="1" />, "Efetue a verificação do robô."],
      });
      return;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (!this.isValidCPF(this.state.cpf)) {
      this.setState({
        answer: [<AlertIcon key="2" />, "Insira um CPF válido."],
      });
      return;
    }

    if (!this.isValidDateOfBirth(this.state.date)) {
      this.setState({
        answer: [
          <AlertIcon key="3" />,
          "Insira uma data de nascimento válida.",
        ],
      });
      return;
    }

    if (!this.isValidPhone(this.state.phone)) {
      this.setState({
        answer: [<AlertIcon key="4" />, "Insira um telefone celular válido."],
      });
      return;
    }

    if (!this.isValidCep(this.state.cep)) {
      this.setState({
        answer: [<AlertIcon key="5" />, "Insira um cep válido."],
      });
      return;
    }

    this.setState({
      answer: [<CubeSendIcon key="6" />, "Enviando sua inscrição..."],
    });

    axios
      .post(
        "https://us-central1-getinfo-web.cloudfunctions.net/api/users/subscription",
        {
          course: this.state.course,
          payment: this.state.payment,
          name: this.state.name,
          email: this.state.email,
          rg: this.state.rg,
          cpf: this.state.cpf,
          date: this.state.date,
          phone: this.state.phone,
          mother: this.state.mother,
          cep: this.state.cep,
          address: this.state.address,
          number: this.state.number,
          complement: this.state.complement,
          neighborhood: this.state.neighborhood,
          city: this.state.city,
          state: this.state.state,
        }
      )
      .then(() => {
        this.setState({ modalIsOpen: true });
        this.setState({
          answer: [
            <CheckCircleIcon key="7" />,
            "Sua inscrição foi efetuada com sucesso!",
          ],
        });
      })
      .catch((error) => {
        this.setState({
          answer: [
            <CloseCircleIcon key="8" />,
            "Ops! Ocorreu algum erro ao enviar sua inscrição. Por favor, tente novamente.",
          ],
        });
        console.log(error);
      });
  };

  render() {
    const { modalIsOpen } = this.state;
    const { onLoadCallback, verifyCallback } = this;

    return (
      <div id="inscricao">
        <div className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">
                <div className="box">
                  <h1>Inscreva-se</h1>
                  <h2>Preencha seus dados abaixo</h2>

                  <form onSubmit={this.handleSubmit}>
                    <div className="field">
                      <label className="label">Treinamento e Turma *</label>
                      <div className="control">
                        <select
                          name="course"
                          className="input"
                          value={this.state.course}
                          onChange={this.handleFormChange}
                        >
                          {/* <option selected value="">Nenhum treinamento em aberto</option> */}
                          <option selected value="">
                            Selecione uma opção
                          </option>
                          <option>
                            Treinamento - THINKING AGILE - 04/06 e 05/06/2020 -
                            8h as 12h e 14h as 18h
                          </option>
                          <option>
                            Treinamento - THINKING AGILE - 05/06 e 06/06/2020 -
                            8h as 12h e 14h as 18h
                          </option>
                          <option>
                            Treinamento - THINKING AGILE - 13/06 e 20/06/2020 -
                            8h as 12h e 14h as 18h
                          </option>
                          <option>
                            Treinamento - THINKING AGILE - 27/06 e 04/07/2020 -
                            8h as 12h e 14h as 18h
                          </option>
                        </select>
                        <span>
                          {this.validator.message(
                            "course",
                            this.state.course,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Forma de pagamento *</label>
                      <div className="control">
                        <select
                          name="payment"
                          className="input"
                          value={this.state.payment}
                          onChange={this.handleFormChange}
                        >
                          <option selected value="">
                            Selecione uma opção
                          </option>
                          <option>À vista (Boleto ou Transferência)</option>
                          <option>Parcelado</option>
                        </select>
                        <span>
                          {this.validator.message(
                            "payment",
                            this.state.payment,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Nome *</label>
                      <div className="control">
                        <input
                          name="name"
                          type="text"
                          className="input"
                          value={this.state.name}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "name",
                            this.state.name,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">E-mail *</label>
                      <div className="control">
                        <input
                          name="email"
                          type="email"
                          className="input"
                          value={this.state.email}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="columns no-margin-bottom">
                      <div className="column">
                        <div className="field">
                          <label className="label">RG *</label>
                          <div className="control">
                            <input
                              name="rg"
                              type="text"
                              className="input"
                              value={this.state.rg}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "rg",
                                this.state.rg,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <label className="label">CPF *</label>
                          <div className="control">
                            <MaskedInput
                              name="cpf"
                              type="text"
                              className="input"
                              value={this.state.cpf}
                              onChange={this.handleFormChange}
                              mask="111.111.111-11"
                              size="14"
                              placeholder=""
                            />
                            <span>
                              {this.validator.message(
                                "cpf",
                                this.state.cpf,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="columns no-margin-bottom">
                      <div className="column">
                        <div className="field">
                          <label className="label">Data de nascimento *</label>
                          <div className="control">
                            <MaskedInput
                              name="date"
                              type="text"
                              className="input"
                              value={this.state.date}
                              onChange={this.handleFormChange}
                              mask="11/11/1111"
                              size="10"
                              placeholder=""
                            />
                            <span>
                              {this.validator.message(
                                "date",
                                this.state.date,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <label className="label">Telefone Celular *</label>
                          <div className="control">
                            <MaskedInput
                              name="phone"
                              type="text"
                              className="input"
                              value={this.state.phone}
                              onChange={this.handleFormChange}
                              mask="(11) 11111-1111"
                              size="13"
                              placeholder=""
                            />
                            <span>
                              {this.validator.message(
                                "phone",
                                this.state.phone,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Nome completo da mãe *</label>
                      <div className="control">
                        <input
                          name="mother"
                          type="text"
                          className="input"
                          value={this.state.mother}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "mother",
                            this.state.mother,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">CEP *</label>
                      <div className="control">
                        <MaskedInput
                          name="cep"
                          type="text"
                          className="input"
                          value={this.state.cep}
                          onChange={this.onChangeCEP}
                          mask="11111-111"
                          size="9"
                          placeholder=""
                        />
                        <span>
                          {this.validator.message(
                            "cep",
                            this.state.cep,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="columns no-margin-bottom">
                      <div className="column is-10">
                        <div className="field">
                          <label className="label">Endereço *</label>
                          <div className="control">
                            <input
                              name="address"
                              type="text"
                              className="input"
                              value={this.state.address}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "address",
                                this.state.address,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="column is-2">
                        <div className="field">
                          <label className="label">Número *</label>
                          <div className="control">
                            <input
                              name="number"
                              type="text"
                              className="input"
                              value={this.state.number}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "number",
                                this.state.number,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="columns no-margin-bottom">
                      <div className="column">
                        <div className="field">
                          <label className="label">Complemento</label>
                          <div className="control">
                            <input
                              name="complement"
                              type="text"
                              className="input"
                              value={this.state.complement}
                              onChange={this.handleFormChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <label className="label">Bairro *</label>
                          <div className="control">
                            <input
                              name="neighborhood"
                              type="text"
                              className="input"
                              value={this.state.neighborhood}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "neighborhood",
                                this.state.neighborhood,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="columns no-margin-bottom">
                      <div className="column">
                        <div className="field">
                          <label className="label">Cidade *</label>
                          <div className="control">
                            <input
                              name="city"
                              type="text"
                              className="input"
                              value={this.state.city}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "city",
                                this.state.city,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <label className="label">Estado *</label>
                          <div className="control">
                            <input
                              name="state"
                              type="text"
                              className="input"
                              value={this.state.state}
                              onChange={this.handleFormChange}
                            />
                            <span>
                              {this.validator.message(
                                "state",
                                this.state.state,
                                "required"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="recaptcha">
                      <Recaptcha
                        sitekey="6LdbZGIUAAAAAIr-e4TTQ2r96Fy2va3yn2sBof7E"
                        render="explicit"
                        onloadCallback={onLoadCallback}
                        verifyCallback={verifyCallback}
                        hl="pt-BR"
                        required
                      />
                    </div>

                    <div className="answer">{this.state.answer}</div>

                    <div className="field">
                      <div className="control">
                        <Button type="submit">Enviar</Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de inscrição realizada com sucesso. */}
        <div className={`modal ${modalIsOpen === true ? "is-active" : null}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <button
                className="delete"
                aria-label="close"
                onClick={this.handleDimissModal.bind(this)}
              ></button>
            </header>
            <section className="modal-card-body">
              <div className="center-icon">
                <i className="material-icons icon">check_circle_outline</i>
              </div>
              <p>
                Inscrição efetuada com sucesso.
                <br />
                Em breve, você receberá mais informações por e-mail.
              </p>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Inscricao;

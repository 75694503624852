import React, { Component } from "react";
import { i18n } from "../../../translate/i18n";

//Componentes
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";
import Web from "./../desenvolvimento/Web";

//images
import HomeAminthas from "../../../assets/images/desenvolvimento/aminthas.png";

class Aminthas extends Component {
  render() {
    return (
      <div className="animated fadeInUp">
        <Card
          color="#4a90e2"
          title={i18n.t("cartorioAminthas.title")}
          text={i18n.t("cartorioAminthas.descricao")}
          icons={<Web />}
          column="8"
          image={HomeAminthas}
        >
          <Button link="/servicos/desenvolvimento/cartorio-aminthas">
            {i18n.t("cartorioAminthas.saibaMais")}
          </Button>
        </Card>
      </div>
    );
  }
}

export default Aminthas;

import React, { Component } from "react";
import { i18n } from "../../../translate/i18n";

//Componentes
import AndroidIos from "./../desenvolvimento/AndroidIos";
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import pajucara from "../../../assets/images/desenvolvimento/pajucara.png";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/pajucara.scss";

class Pajucara extends Component {
  render() {
    return (
      <div id="pajucara" className="animated fadeInUp">
        <Card
          click={() => this.modalPajucara}
          color="#4a4a4a"
          title={i18n.t("pajucara.title")}
          text={i18n.t("pajucara.descricao")}
          icons={<AndroidIos />}
          column="8"
          image={pajucara}
        >
          <Button link="/servicos/desenvolvimento/pajucara">
            {i18n.t("pajucara.saibaMais")}
          </Button>
        </Card>
      </div>
    );
  }
}

export default Pajucara;

import React from "react";

//CSS
import "../../../assets/styles/components/Servicos/business/imagensBusiness.scss";

const Item = props => {
  return props.imagePosition === "left" ? (
    <div id="imagensComponent">
      <div className="columns is-vcentered">
        <div className="column is-6">
          <img src={props.image} alt="" />
        </div>
        <div className="column is-5 is-offset-1">
          <p className="subtitulo">{props.title}</p>
          <p className="descricao">{props.text}</p>
        </div>
      </div>
    </div>
  ) : props.imagePosition === "center" ? (
    <div id="imagensComponent">
      <div className="columns is-centered">
        <div className="column is-6 has-text-centered">
          <p className="subtitulo">{props.title}</p>
          <p className="descricao">{props.text}</p>
        </div>
      </div>
      <div className="columns is-centered">
        <div className={"column is-" + props.column}>
          <img src={props.image} alt="" style={{ marginTop: "30px" }} />
        </div>
      </div>
    </div>
  ) : (
    <div id="imagensComponent">
      <div className="columns is-vcentered">
        <div className="column is-6">
          <p className="subtitulo">{props.title}</p>
          <p className="descricao">{props.text}</p>
        </div>
        <div className="column is-5 is-offset-1">
          <img src={props.image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Item;

import React from "react";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/infoComponent.scss";

const Info = props => {
  return (
    <div id="info">
      <div className="section">
        <div className="container">
          <div className="columns is-centered pagina">
            <div className="column has-text-centered is-6">
              <span className="is-pulled titulo" style={{ color: props.color }}>
                {props.title}
              </span>
            </div>
          </div>
          <div className="columns is-centered pagina">
            <div className="column has-text-centered is-6">
              <p className="texto">{props.descricao}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered margin-bottom-none">
        <div className="column padding-bottom-none">
          <img src={props.image} alt="" className="img-principal" />
        </div>
      </div>
      {props.noContainer ? (
        <div>{props.children}</div>
      ) : (
        <div className="section">
          <div className="container">{props.children}</div>
        </div>
      )}
    </div>
  );
};

export default Info;

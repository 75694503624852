import React, { Component } from "react";
import { i18n } from "../../../translate/i18n";
//Componentes
import AndroidIosWeb from "./../desenvolvimento/AndroidIosWeb";
// import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import cas from "../../../assets/images/desenvolvimento/cas.png";

//CSS
// import "../../../assets/styles/components/Servicos/desenvolvimento/cas.scss";

class CAS extends Component {
  render() {
    return (
      <div className="animated fadeInUp">
        <Card
          color="#5F9B5F"
          title={i18n.t("clinicaCas.title")}
          text={i18n.t("clinicaCas.descricao")}
          icons={<AndroidIosWeb />}
          column="6"
          image={cas}
        >
          {/* <Button link="/servicos/desenvolvimento/cas">Ver mais</Button> */}
        </Card>
      </div>
    );
  }
}

export default CAS;

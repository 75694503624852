import React, { Component } from "react";
import { i18n } from "../../../../translate/i18n";
//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";
//Imagens
import portal from "../../../../assets/images/desenvolvimento/Pajucara/pajucara6.png";
import principal from "../../../../assets/images/desenvolvimento/Pajucara/pajucara1.png";
import radios from "../../../../assets/images/desenvolvimento/Pajucara/pajucara4.png";
import reporter from "../../../../assets/images/desenvolvimento/Pajucara/pajucara5.png";
import tv from "../../../../assets/images/desenvolvimento/Pajucara/pajucara3.png";
import veiculos from "../../../../assets/images/desenvolvimento/Pajucara/pajucara2.png";
import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import piox from "../../../../assets/images/desenvolvimento/MaisProjetos/piox.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";
//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/pajucara.scss";

class Pajucara extends Component {
  render() {
    return (
      <div id="pajucara" className="animated fadeIn">
        <Info
          title="Pajuçara"
          color="rgba(0, 0, 0, 0.87)"
          descricao={i18n.t("pajucara.descricao")}
          image={principal}
        >
          <Imagem image={veiculos}>
            <span>
              <p className="title color-black">
                {i18n.t("pajucara.veiculosDeComunicacao.title")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.veiculosDeComunicacao.descricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={tv} imagePosition="left">
            <span>
              <p className="title color-red">
                {i18n.t("pajucara.tvPajucara.title")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.tvPajucara.descricao")}
              </p>
            </span>
          </Imagem>
          <Imagem color="rgba(0, 0, 0, 0.87)" image={radios} titulo="Rádios">
            <span>
              <p className="subtitle color-orange">
                {i18n.t("pajucara.radios.title")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.radios.radioarapiracadescricao")}
              </p>
              <br />
              <p className="subtitle color-green">
                {i18n.t("pajucara.radios.avaliacoes")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.radios.avaliacoesdescricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={reporter} imagePosition="left">
            <span>
              <p className="title color-blue">
                {i18n.t("pajucara.vocereporter.title")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.vocereporter.descricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={portal}>
            <span>
              <p className="title color-dark-blue">
                {i18n.t("pajucara.portalDeNoticias.title")}
              </p>
              <p className="descricao">
                {i18n.t("pajucara.portalDeNoticias.descricao")}
              </p>
            </span>
          </Imagem>

          <DownloadApp
            ios="https://apps.apple.com/us/app/pajuplus/id1526881002"
            android="https://play.google.com/store/apps/details?id=br.com.pajucara2"
          />
        </Info>

        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={amadeus}
            title="Colégio Amadeus"
            link="/servicos/desenvolvimento/amadeus"
          />
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={piox}
            title="PioNET"
            link="/servicos/desenvolvimento/pionet"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default Pajucara;

import React, { Component } from "react";
import { i18n } from "../../translate/i18n.js";
import ScrollAnimation from "react-animate-on-scroll";

// Assets
import background from "./../../assets/images/bg1.png";

export default class Banner extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn">
        <div id="banner">
          <div
            className="bg"
            style={{ background: `url(${background}) center  center / cover` }}
          >
            <div className="section">
              <div className="container">
                <ScrollAnimation animateIn="jackInTheBox">
                  <h1>
                    {i18n.t("banner.voceTemaIdeia")}{" "}
                    <br className="is-hidden-mobile" />
                    {i18n.t("banner.nosTemosaSolucao")}
                  </h1>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}

import React from "react";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/cardComponent.scss";

const Card = props => {
  return (
    <div id="card">
      <div className="container espacamento">
        <div className="box is-centered color">
          <p
            className="has-text-centered titulo"
            style={{ color: props.color }}
          >
            {props.title}
          </p>
          <br />
          <div className="columns is-centered">
            <div className="column is-6">
              <p className="has-text-centered texto">{props.text}</p>
            </div>
          </div>
          <div className="columns is-centered imagem">
            <div className={"column is-" + props.column}>
              <img src={props.image} alt="" />
            </div>
          </div>
          {props.children}
          {props.icons}
        </div>
      </div>
    </div>
  );
};

export default Card;

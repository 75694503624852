import React, { Component } from "react";

//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";

//Imagens
import principal from "../../../../assets/images/desenvolvimento/CAS/cas1.png";
import cas2 from "../../../../assets/images/desenvolvimento/CAS/cas2.png";
import cas3 from "../../../../assets/images/desenvolvimento/CAS/cas3.png";
import cas4 from "../../../../assets/images/desenvolvimento/CAS/cas4.png";

import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import pajucara from "../../../../assets/images/desenvolvimento/MaisProjetos/pajucara.png";
import piox from "../../../../assets/images/desenvolvimento/MaisProjetos/piox.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/cas.scss";

class CAS extends Component {
  render() {
    return (
      <div id="cas" className="animated fadeIn">
        <Info
          title="Clínica CAS"
          color="#5F9B5F"
          descricao={`Plataforma desenvolvida para a Clínica de Anestesia de Salvador, no intuito de agilizar os processos internos na clínica e ter todos os dados das cirurgias documentados de forma digital.`}
          image={principal}
        >
          <Imagem image={cas2}>
            <span>
              <p className="title">Lorem ipsum</p>
              <p className="descricao">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </span>
          </Imagem>
          <Imagem image={cas3} imagePosition="left">
            <span>
              <p className="title">Lorem ipsum</p>
              <p className="descricao">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </span>
          </Imagem>
          <Imagem
            color="#5F9B5F"
            image={cas4}
            titulo="Arquivos"
          >
            <span>
              <p className="subtitle">Lorem ipsum</p>
              <p className="descricao">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <p className="subtitle">Lorem ipsum</p>
              <p className="descricao">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <p className="subtitle">Lorem ipsum</p>
              <p className="descricao">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </span>
          </Imagem>

          <DownloadApp
            ios="https://itunes.apple.com/br/app/col%C3%A9gio-amadeus/id1180187875?mt=8"
            android="https://play.google.com/store/apps/details?id=br.net.getinfo.colegio.amadeus"
          />
        </Info>

        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={pajucara}
            title="Pajuçara"
            link="/servicos/desenvolvimento/pajucara"
          />
          <Projeto
            image={piox}
            title="PioNET"
            link="/servicos/desenvolvimento/pionet"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default CAS;

import React, { Component } from "react";
import { i18n } from "../../../translate/i18n";

//Componentes
import Card from "./../desenvolvimento/CardComponent";
import AndroidIosWeb from "./../desenvolvimento/AndroidIosWeb";
import Button from "./../../general/Button";

//images
import image from "../../../assets/images/desenvolvimento/clubedevantagens.png";

class ClubeVantagens extends Component {
  render() {
    return (
      <div className="animated fadeInUp">
        <Card
          color="#ff1956"
          title={i18n.t("clubeDeVantagens.title")}
          text={i18n.t("clubeDeVantagens.descricao")}
          icons={<AndroidIosWeb />}
          column="8"
          image={image}
        >
          <Button disabled>{i18n.t("clubeDeVantagens.emBreve")}</Button>
        </Card>
      </div>
    );
  }
}

export default ClubeVantagens;

import React, { Component } from "react";
import { i18n } from "../../translate/i18n.js";
import { Columns, Column } from "bloomer";
import { NavLink } from "react-router-dom";

import ScrollAnimation from "react-animate-on-scroll";

export default class Quadros extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" delay={1000}>
        <div id="quadros">
          <div className="section">
            <div className="container">
              <Columns className="box">
                <Column>
                  <NavLink to="/servicos/desenvolvimento">
                    <i className="material-icons">devices_other</i>
                    <p>{i18n.t("menu.servicos.desenvolvimentoDeSoftwares")}</p>
                  </NavLink>
                </Column>
                <hr />
                <Column className="border-center">
                  <NavLink to="/servicos/governanca">
                    <i className="material-icons">business</i>
                    <p>
                      {i18n.t("menu.servicos.governanca")}
                      <br />
                      {i18n.t("menu.servicos.ti")}
                    </p>
                  </NavLink>
                </Column>
                <hr />
                <Column>
                  <NavLink to="/servicos/business">
                    <i className="material-icons">multiline_chart</i>
                    <p>{i18n.t("menu.servicos.bussinessInteligence")}</p>
                  </NavLink>
                </Column>
              </Columns>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}

import React, { Component } from "react";
import { i18n } from "../../translate/i18n";
import { Columns, Column } from "bloomer";
import Button from "./../../components/general/Button";

import ScrollAnimation from "react-animate-on-scroll";

// Assets
import treinamentos from "./../../assets/images/treinamentos/treinamentos.png";

export default class Treinamentos extends Component {
  render() {
    return (
      <div id="dev-schole-spa-infra-getinfo" className="treinamentos">
        <div className="section">
          <div className="container">
            <Columns>
              <Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <h1>{i18n.t("home.getiCursos")}</h1>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <p className="description">{i18n.t("home.descricao")}</p>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <Button link="/treinamentos">
                      {i18n.t("home.saibaMais")}{" "}
                      <i className="material-icons">arrow_forward</i>
                    </Button>
                  </ScrollAnimation>
                </Column>
              </Column>
              <Column className="img-column">
                <ScrollAnimation animateIn="fadeInRight">
                  <img src={treinamentos} alt="" />
                </ScrollAnimation>
              </Column>
            </Columns>
          </div>
        </div>
      </div>
    );
  }
}

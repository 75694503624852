import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import "../assets/styles/views/covid.scss";

class Covid extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.validator = new SimpleReactValidator({
      messages: {
        default: "Campo obrigatório"
      }
    });
  }

  render() {
    return (
      <div id="covid" className="animated fadeInUp">
        <div className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-11 is-centered">
                <h1>
                  Acompanhe os dados mais recentes sobre a COVID-19 em Sergipe
                </h1>
                <div className="box">
                  <div className="covid-container">
                    <iframe
                      // width="800"
                      // height="600"
                      title="covid-panel"
                      className="covid-panel"
                      src="https://app.powerbi.com/view?r=eyJrIjoiZTY0MjI2MmQtMDExMS00MzJmLWIyZTYtZGIzOWJmMDNmODgwIiwidCI6ImM2ZjMwMGNmLWY3NTItNGQwMC1iOGU4LTI2YTU1ZTI0ZGNjMCJ9"
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Covid;

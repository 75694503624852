import React, { Component } from "react";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/androidIosWeb.scss";

class Web extends Component {
  render() {
    return (
      <div id="android-ios-web">
        <div align="center" className="columns is-centered espacamento">
          <div className="column is-2 ">
            <i className="material-icons icone">code</i>
            <span className="texto">Web</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Web;

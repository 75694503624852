import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/views/404.scss";
import { i18n } from "../translate/i18n.js";

class PageNotFound extends Component {
  render() {
    return (
      <div id="page404">
        <div className="hero is-large is-gradient">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">{i18n.t("404.paginanaoencontrada")}</h1>
              <h2 className="sub-title">{i18n.t("404.paginanaoexiste")}</h2>
              <br />
              <Link className="button is-info is-inverted" to="/">
                {i18n.t("404.voltaraoinicio")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;

import React from 'react';

//CSS
import '../assets/styles/components/ad/ad.scss';

const CellPhones = ( props ) => (
  <img src={props.image} alt="" />
);

const TextContent = (props) => (
  <div>
    <h1 className="titulo-ad">{props.title}</h1>
    {props.children}
  </div>
);

const Ad = (props) =>{
  return(
    <div id="ad" className="animated fadeIn">
      <div className="section alinhamento">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6 is-5-widescreen is-offset-1-widescreen">
              <TextContent title={props.title} />
              {props.children}
            </div>
            <div className="column is-6 imagem-ad">
              <CellPhones image={props.image}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ad;

import React, { Component } from "react";
import { i18n } from "../../../../translate/i18n";

//Componentes
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";

//Imagens
import principal from "../../../../assets/images/desenvolvimento/Aminthas/cartorio3.png";
import site from "../../../../assets/images/desenvolvimento/Aminthas/cartorio2.png";

import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import pajucara from "../../../../assets/images/desenvolvimento/MaisProjetos/pajucara.png";
import piox from "../../../../assets/images/desenvolvimento/MaisProjetos/piox.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/cartorio.scss";

class Cartorio extends Component {
  render() {
    return (
      <div id="cartorio" className="animated fadeIn">
        <Info
          title={i18n.t("cartorioAminthas.title")}
          color="#4a90e2"
          descricao={i18n.t("cartorioAminthas.descricao")}
          image={principal}
          noContainer={true}
        >
          <div className="site">
            <div className="section">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column">
                    <img src={site} className="image-site" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns is-centered">
            <div className="column margin-full-project">
              <p className="title">
                {i18n.t("cartorioAminthas.verProjeto")}
                <br />
                <a
                  href="http://quintooficio.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.quintooficio.com.br
                </a>
              </p>
            </div>
          </div>
        </Info>

        <MaisProjetos>
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={pajucara}
            title="PajuPlus"
            link="/servicos/desenvolvimento/pajucara"
          />
          <Projeto
            image={piox}
            title="PioNET"
            link="/servicos/desenvolvimento/pionet"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default Cartorio;

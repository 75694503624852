import React, { Component } from "react";
import { i18n } from "../translate/i18n";
//CSS
import "../assets/styles/pages/governanca.scss";
//Componentes
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";
//Imagens
import governanca1 from "../assets/images/governanca/governanca1.png";
import governanca2 from "../assets/images/governanca/governanca2.png";
import governanca3 from "../assets/images/governanca/governanca3.png";

class Governanca extends Component {
  render() {
    return (
      <div id="governanca">
        <Servico
          icon="business"
          title={i18n.t("governanca.title")}
          descricao={i18n.t("governanca.descricao")}
        />
        <div className="section animated fadeInUp">
          <div className="container">
            <div className="columns is-vcentered governanca">
              <div className="column is-6">
                <img src={governanca1} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">
                  {i18n.t("governanca.destaqueSeusNegocios")}
                </p>
                <p className="descricao">
                  {i18n.t("governanca.destaqueDescricao")}
                </p>
              </div>
            </div>

            <div className="columns is-vcentered governanca is-hidden-mobile">
              <div className="column is-6">
                <p className="titulo">
                  {i18n.t("governanca.aumenteACompetitividade")}
                </p>
                <p className="descricao">
                  {i18n.t("governanca.competitividadeDescricao")}
                </p>
              </div>
              <div className="column is-5 is-offset-1">
                <img src={governanca2} alt="" />
              </div>
            </div>

            {/* Visivel apenas no Mobile, por causa da ordem das imagens */}
            <div className="columns is-vcentered governanca is-hidden-tablet">
              <div className="column is-6">
                <img src={governanca2} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">
                  {i18n.t("governanca.aumenteACompetitividade")}
                </p>
                <p className="descricao">
                  {i18n.t("governanca.competitividadeDescricao")}
                </p>
              </div>
            </div>

            <div className="columns is-vcentered governanca final">
              <div className="column is-6">
                <img src={governanca3} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">{i18n.t("governanca.suporteTecnico")}</p>
                <p className="descricao">
                  {i18n.t("governanca.suporteTecnicoDescricao")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <FaleConosco textButton={i18n.t("governanca.textButton")} />
      </div>
    );
  }
}

export default Governanca;

import React, { Component } from "react";
import { i18n } from "../translate/i18n.js";
import Servico from "../components/Servicos/ServiceComponent";
import Treinamento from "../components/treinamentos/Treinamento";
import "../assets/styles/views/treinamentos.scss";

class TreinamentoAgile extends Component {
  render() {
    return (
      <div id="treinamentos">
        <Servico
          icon="description"
          title={i18n.t("treinamentos.treinamentoAgile.title")}
          colorTitle="#5c9019"
          descricao={i18n.t("treinamentos.treinamentoAgile.descricao")}
        />
        <hr />
        <div className="section animated fadeInUp">
          <div className="container">
            <Treinamento
              warning
              dataTurma={i18n.t("treinamentos.treinamentoAgile.dataTurma")}
              investimento={i18n.t(
                "treinamentos.treinamentoAgile.investimento"
              )}
              horario={i18n.t("treinamentos.treinamentoAgile.horario")}
              instrutorNome={i18n.t(
                "treinamentos.treinamentoAgile.instrutorNome"
              )}
              instrutorDescricao={i18n.t(
                "treinamentos.treinamentoAgile.instrutorDescricao"
              )}
              objetivo={i18n.t("treinamentos.treinamentoAgile.objetivo")}
              publicoAlvo={i18n.t("treinamentos.treinamentoAgile.publicoAlvo")}
              conteudoProgramatico={i18n.t(
                "treinamentos.treinamentoAgile.conteudoProgramatico"
              )}
              habilitado={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TreinamentoAgile;
